/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1202.1329
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Image } from './image';
import { Project } from './project';
import { Counterparty, Organization, User } from "@topseller/core";
import { Status } from './status';
import { Tag } from './tag';
import { HubDocument } from "./document";
import { PurchaseProduct } from "./purchase-product";

export interface Purchase extends HubDocument {
  isReceive: boolean;
  plannedAt?: number;
  credited?: boolean;
  paid?: boolean;
  isPriceIncludesVat: boolean;
  isFinalized: boolean;
  isVat: boolean;
  amount: number;
  amountAccepted: number;
  amountResolved: number;
  amountReturn: number;
  amountVat: number;
  amountReceived: number;
  amountWithVat: number;
  amountPayment: number;
  organization?: Organization;
  counterparty?: Counterparty;
  project?: Project;
  customFields: { id: string; value: string | boolean }[];
  status?: Status;
  tags?: Array<Tag>;
  images?: Array<Image>;
  lastEditedBy?: User;
  responsibleBy?: User;
  note?: string;
  products: Array<PurchaseProduct>
}
