<div class="catalog-table bordered-light">
  <div class="catalog-table__header">
    <ts-dropdown [content]="actions">
      <div class="btn btn-outline-primary">
        Действия
        <span>
          <svg id="check">
            <use xlink:href="/assets/img/icons/chevron-down.svg#main"></use>
          </svg>
        </span>
      </div>
    </ts-dropdown>

    <ng-template #actions>
      <button class="dropdown-item" [class.disable]="!selected.length" dropdownOption (dropdownSelect)="deleteLines()">
        Удалить
      </button>
    </ng-template>

    <div class="search bordered-light">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="7" stroke="#A5AEC0" stroke-width="2" />
        <path d="M20 20L17 17" stroke="#A5AEC0" stroke-width="2" stroke-linecap="round" />
      </svg>

      <input type="text" class="search__field" placeholder="Поиск по наименованию и артикулу товара"
        [formControl]="search" />
    </div>
  </div>

  <!-- PRODUCT LIST TABLE -->
  <div class="entity-list__table">
    <div style="flex: 1; overflow: auto; position: relative">
      <div class="entity-list__table-row header">
        <div class="entity-list__table-cell" [ngStyle]="{
            flexBasis: header.width ? header.width + 'px' : false,
            width: header.width ? header.width + 'px' : false
          }" *ngFor="let header of tableHeaders; let i = index">
          <ng-template #cellContext>
            <div class="content">
              <span>{{ header.title }}</span>
            </div>
          </ng-template>
          <ts-checkbox *ngIf="header.name === 'checkbox'; else cellContext" [active]="allSelected"
            [indeterminate]="!allSelected && !!selected.length" (toggle)="toggleAll($event)"></ts-checkbox>

          <ng-template #cellContext>
            <div class="content">
              <span>{{ header.title }}</span>
            </div>
          </ng-template>
        </div>
      </div>

      <div *ngFor="let line of products$ | async; index as i; let last = last" class="entity-list__table-row"
        [formGroup]="line">
        <div class="entity-list__table-cell" [ngStyle]="{
            flexBasis: header.width ? header.width + 'px' : false,
            width: header.width ? header.width + 'px' : false
          }" *ngFor="let header of tableHeaders; let i = index">
          <ng-container [ngSwitch]="header.name">
            <ts-checkbox *ngSwitchCase="'checkbox'" [active]="isSelected(line.value.id)"
              (toggle)="select($event, line.value?.product?.id)"></ts-checkbox>

            <div *ngSwitchCase="'sku'" class="content">
              <span>{{ line.value?.product?.sku }}</span>
            </div>

            <div class="flex-grow-1" *ngSwitchCase="'product'">
              <ts-select [focused]="!isTableIdle && last" (focusChanged)="lineAdded()"
                         size="cell" formControlName="product" [identityMatcher]="recordIdentityMatcher"
                         [stringify]="recordStringify">
                <ts-entity-list [queryFn]="productQueryFn"></ts-entity-list>
              </ts-select>
            </div>

            <input *ngSwitchCase="'quantity'" class="input" tsInputNumber formControlName="quantity"
              [class.invalid]="isInvalid(line, 'quantity')" />

            <input *ngSwitchCase="'price'" class="input" tsInputNumber formControlName="price"
              [placeholder]="line.value.product.price | number : '1.2-3'" />

            <div *ngSwitchCase="'stock'" class="content">
              <span>{{ line.value?.product?.stocks | number : '1.0-3' }}</span>
            </div>

            <div class="content" *ngSwitchCase="'amount'">
              <span>{{
                line.value?.quantity * line.value?.price | number : '1.2-2'
                }}</span>
            </div>

            <div class="content" *ngSwitchCase="'volume'">
              <span>{{
                line.value?.product?.volume * line.value?.quantity
                | number : '1.0-3'
                }}</span>
            </div>

            <div class="content" *ngSwitchCase="'weight'">
              <span><span>{{
                  line.value?.product?.weight * line.value?.quantity
                  | number : '1.0-3'
                  }}</span></span>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="entity-list__table-row new-line">
        <div [ngStyle]="{
            flexBasis: header.width ? header.width + 'px' : false,
            width: header.width ? header.width + 'px' : false
          }" class="entity-list__table-cell data-cell" *ngFor="let header of tableHeaders; let i = index">
          <div class="flex-grow-1" *ngIf="header.name === 'product'">
            <ts-select  size="cell" placeholder="Выберите товар"
                       [formControl]="linePlaceholder" [identityMatcher]="recordIdentityMatcher"
                       [stringify]="recordStringify">
              <ts-entity-list [queryFn]="productQueryFn"></ts-entity-list>
            </ts-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="totals" *ngIf="totals$">
    <div *ngFor="let info of totals$ | async">
      <p>{{ info.title }}:</p>
      <p>{{ info.value | number : '1.2-2' }} РУБ</p>
    </div>
  </div>
</div>
