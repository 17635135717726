import { Component, inject, OnInit } from '@angular/core';
import { SelectionOfGoodsFromCatalogComponent } from "../selection-of-goods-from-catalog.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ENTITY_NAME } from "../../../tokens";
import { HubEntity } from "../../../../data";

@Component({
  selector: 'ts-catalog-modal-wrapper',
  templateUrl: './catalog-modal-wrapper.component.html',
  styleUrls: ['./catalog-modal-wrapper.component.scss'],
})
export class CatalogModalWrapperComponent implements OnInit {
  private parentEntity: HubEntity;
  constructor(private dialog: MatDialog,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.parentEntity = inject(ENTITY_NAME);
  }

  ngOnInit() {
    const products = history.state.products;
    // если мы сюда попали не через нажатие кнопки, а по прямому юрл, то модалку не открываем и возвращаемся к корню
    if (!products) {
      this.router.navigate([{outlets: {modal: null}}],
        {
          relativeTo: this.activatedRoute.parent,
        });
      return;
    }


    const dialogConfig = {
      disableClose: true,
      backdropClass: 'ts-modal-backdrop',
      data: {
        products,
        parentEntity:this.parentEntity
      },
      panelClass: ['ts-modal-panel', 'ts-modal-panel-full-height'],
      width: '100vw',
      maxWidth: '100vw',
      height: '97dvh',
      id: 'ts-modal',
    }

    const dialogRef = this.dialog.open(
      SelectionOfGoodsFromCatalogComponent,
      dialogConfig
    );

    dialogRef.afterClosed()
      .subscribe(
        selectedProducts => {
          this.router.navigate([{outlets: {modal: null}}],
            {
              relativeTo: this.activatedRoute.parent,
              state: {selectedProducts}
            });
        }
      );
  }
}
