import { Component, Input } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: 'ts-copyable-cell',
  templateUrl: './copyable-cell.component.html',
  styleUrls: ['./copyable-cell.component.scss'],
})
export class CopyableCellComponent {
  @Input() value:any;

  constructor(private toastr: ToastrService,
              private clipboardService: ClipboardService) {
  }

  copyToClipboard(event:MouseEvent,) {
    event.preventDefault();
    event.stopPropagation();
    this.clipboardService.copyFromContent(this.value);
    this.toastr.info(`${this.value} скопировано`);
  }
}
