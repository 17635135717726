import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-image',
  templateUrl: './button-image.component.html',
  styleUrls: ['./button-image.component.scss'],
})
export class ButtonImageComponent {
  @Input() src = '';
}
