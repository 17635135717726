<ul class="navbar-nav">
  <ng-container *ngFor="let link of links">
    <li class="nav-item" routerLinkActive="active" *ngIf="link.title">
      <a class="nav-link" [routerLink]="link.path">
        <img src="{{ link.imgPath }}" class="nav-icon" alt="Nav icon" />
        <span class="link-title">{{ link.title }}</span>
      </a>
    </li>
  </ng-container>
</ul>
