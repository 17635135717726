import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entity',
})
export class EntityNamePipe implements PipeTransform {
  transform(item: any, itemKey: string): string | number {
    const key = itemKey as keyof typeof item;
    const data = item[key];

    if (data && data.name) {
      return data.name;
    }

    return data && (typeof data === 'string' || typeof data === 'number')
      ? data
      : '';
  }
}
