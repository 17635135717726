export interface IndicatorSettings {
  hidden: boolean;
}

export interface IndicatorMetadata {
  label: string;
  canBeHidden: boolean;
}

export interface Indicator {
  settings: IndicatorSettings;
  metadata: IndicatorMetadata;
}
