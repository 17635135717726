<nav class="navbar nav-bar navbar-expand-sm">
  <a class="navbar-brand" [routerLink]="appLink">
    <img
      [src]="'assets/img/icons/products/' + productName + '.svg'"
      width="22"
      height="28"
      alt="topseller"
    />
    <div class="title">{{ appTitle }}</div>
  </a>
  <button
    class="navbar-toggler nav-icon-button"
    type="button"
    (click)="isMobileMenuOpen = !isMobileMenuOpen"
  >
    <span
      class="ts-icon"
      [ngClass]="{
        'ts-icon-actions-Close': isMobileMenuOpen,
        'ts-icon-menu-Menu3': !isMobileMenuOpen
      }"
    ></span>
  </button>

  <div class="collapse navbar-collapse" [ngClass]="{ show: isMobileMenuOpen }">
    <ul class="navbar-nav me-auto">
      <ng-container *ngFor="let link of links">
        <ts-role-access-wrapper [requiredRole]="link.requiredRole" *ngIf="isVisible(link)">
          <li class="nav-item" *ngIf="link.title">
            <a
              class="nav-link"
              [routerLink]="link.path"
              [tsNavbar]="this.appName"
              routerLinkActive="nav-link-active"
            >
            <span
              *ngIf="link.iconName"
              class="ts-icon text-normal"
              [ngClass]="'ts-icon-' + link.iconName"
            ></span>
              <div class="nav-link__title">{{ link.title }}</div>
            </a>

            <ng-template #noAccessHover>
              <div>Ограничено правами доступа</div>
            </ng-template>
          </li>
        </ts-role-access-wrapper>
      </ng-container>
    </ul>
    <div class="me-2">
      <ul class="navbar-nav">
        <li class="nav-item">
          <ts-key-info></ts-key-info>
        </li>
        <li class="nav-item">
          <button type="button" class="nav-icon-button-with-text" [tsHint]="notificationsHint">
            <span class="ts-icon ts-icon-notifications-Alarm_noty"></span>
            <div class="nav-icon-button-with-text__title">Уведомления</div>
          </button>
        </li>
        <li class="nav-item">
          <ts-user-info></ts-user-info>
        </li>
      </ul>
    </div>
  </div>
</nav>
<ng-template #notificationsHint>Уведомления</ng-template>

<ng-container *ngIf="activeLink?.sub?.length">
  <div class="sub-nav-container">
    <ts-scroll>
      <ul class="navbar-nav sub-nav">
        <ts-role-access-wrapper [requiredRole]="link.requiredRole" *ngFor="let link of activeLink!.sub!">
          <li
            class="sub-nav__item"
            [ngClass]="{ 'sub-nav__item-active': isCurrentRoute(link) }"
          >
            <a class="nav-link" [routerLink]="link.path" tsNavbar>
              {{ link.title }}
            </a>
          </li>
        </ts-role-access-wrapper>
      </ul>
    </ts-scroll>
  </div>
</ng-container>
