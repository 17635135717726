/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0131.1845
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PostingProduct } from './posting-product';
import { Project } from './project';
import { Status } from './status';
import { Organization, TsExtendedDataEntity, Warehouse } from "@topseller/core";
import { Inventory } from "./inventory";
import { Currency } from "./currency";
import { CustomFieldValue } from "./customFieldValue";
import { HubDocument } from "./document";

export interface Posting extends HubDocument {

  /**
   * Организация (обязательное при создании)
   */
  organization?: Organization;
  /**
   * Сумма документа, int 1/100
   */
  readonly amount?: number;
  /**
   * Общий вес, int граммы
   */
  readonly weight?: number;
  /**
   * Общее количество позиций
   */
  readonly quantity?: number;
  /**
   * Валюта
   */
  currency?: Currency;
  /**
   * Проект
   */
  project?: Project;
  /**
   * Статус (если не указан, будет Новый)
   */
  status?: Status;
  /**
   * Документ-основание (inventory)
   */
  inventory?: Inventory;
  /**
   * Объем продуктов/товаров
   */
  volume?: number;
  /**
   * Массив продуктов/товаров
   */
  products?: Array<PostingProduct>;

  /**
   * Документ-основание (ID)
   */
  entityId?: string;
  /**
   * Дата создания
   */
  readonly createdAt?: Date;
  /**
   * Кастомные поля (массив)
   */
  customFields?: Array<CustomFieldValue>;

  /**
   * ID записей в корзине (если удален)
   */
  readonly linesDeleted?: Array<string>;
  /**
   * Примечание
   */
  note?: string;
}
