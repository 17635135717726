import { Product } from './product';
import { Vat } from './vat';

export interface PurchaseProduct {
  id: string;
  quantity: number;
  received?: number;
  reverted?: number;
  price: number;
  vat: number;
  vatType: Vat;
  amount?: number;
  vatPrice?: number;
  discountPricePercent?: number;
  weight?: number;
  volume?: number;
  product?: Product;
}
