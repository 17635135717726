<div class="row align-items-center">
  <div class="col-12 col-sm-6 col-md-4 col-xl-2" *ngFor="let field of formFields$ | async; let index = index">
    <ng-container [ngSwitch]="field.type">
      <ts-base-input
        *ngSwitchCase="CustomFieldTypes.TEXT"
        [value]="getStringValue(field.id)"
        [label]="field.name"
        (valueChange)="valueChanges(field, $event)"
        [readonly]="disabled"
        [disabled]="disabled"
        [class.disabled]="disabled"
      />

      <ts-input-link
        *ngSwitchCase="CustomFieldTypes.LINK"
        [ngModel]="getStringValue(field.id)"
        [label]="field.name"
        (valueChange)="valueChanges(field, $event)"
        [readonly]="disabled"
        [disabled]="disabled"
        [class.disabled]="disabled"
      />

      <ts-base-input
        *ngSwitchCase="CustomFieldTypes.NUMBER"
        [mask]="numberMask"
        [value]="getStringValue(field.id)"
        [label]="field.name"
        (valueChange)="valueChanges(field, $event)"
        [readonly]="disabled"
        [disabled]="disabled"
        [class.disabled]="disabled"
      />

      <ts-input-currency
        *ngSwitchCase="CustomFieldTypes.FRACTIONAL"
        [label]="field.name"
        [ngModel]="getStringValue(field.id)"
        (valueChange)="valueChanges(field, $event)"
      />

      <ts-input-date-time
        *ngSwitchCase="CustomFieldTypes.DATETIME"
        [label]="field.name"
        [ngModel]="getStringValue(field.id)"
        (valueChange)="valueChanges(field, $event)"
        [disabled]="disabled"
      />

      <app-toggle-switch
        *ngSwitchCase="CustomFieldTypes.BOOLEAN"
        [label]="field.name"
        [ngModel]="getStringValue(field.id)"
        (changed)="valueChanges(field, $event)"
        [disabled]="disabled"
        [class.disabled]="disabled"
      />

    </ng-container>
  </div>
</div>
