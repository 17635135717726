/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Record } from "@topseller/core";
import { Vat } from "./vat";

export interface ProductGroup extends Record {
  note?: string;
  externalCode?: string;
  vat?: number;
  vatType?: Vat;
  productGroups?: Array<ProductGroup>;
  parentGroup?:ProductGroup;
}
