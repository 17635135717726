<img
  [src]="getImage()"
  [alt]="type"
  width="20px"
  height="20px"
  style="object-fit: cover"
  [tsHint]="text"
/>

<ng-template #text>
  <span>{{ getText() }}</span>
</ng-template>
