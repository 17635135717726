import { HubDocument } from './document';
import { Counterparty, Organization } from '@topseller/core';
import { Project } from './project';
import { Status } from './status';
import { CustomFieldValue } from './customFieldValue';
import { OrderProduct } from './order-product';

export interface CommissionerDemand extends HubDocument {
  organization?: Organization;
  project?: Project;
  counterparty?: Counterparty;
  status?: Status;
  deliveryAddress?: string;
  deliveryComment?: string;
  note?: string;
  customFields?: Array<CustomFieldValue>;
  products: Array<OrderProduct>;
}
