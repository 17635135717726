import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-attention-tooltip',
  templateUrl: './attention-tooltip.component.html',
  styleUrls: ['./attention-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttentionTooltipComponent {
  @Input() tooltip: string | undefined;
}
