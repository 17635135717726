import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { debounceTime, map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss'],
})
export class LabelInputComponent {
  value = '';
  isLoading = false;

  options: any[] = [];

  @Input() service: any;
  @Input() methodName?: string;
  @Input() items?: any[];

  @Output() selected = new EventEmitter();

  constructor(private changeDetector: ChangeDetectorRef) {}

  searchItem(): void {
    this.options = [];
    this.isLoading = true;
    this.service[this.methodName!](this.value)
      .pipe(
        debounceTime(250),
        shareReplay(),
        map((i: any) => i.items)
      )
      .subscribe((items: any[]) => {
        if (this.items && this.items.length) {
          items = items.filter((i) => {
            return !this.items?.find((item) => item.id === i.id);
          });
        }

        this.options = items;
        this.isLoading = false;
        this.changeDetector.detectChanges();
      });
  }

  selectItem(item: any): void {
    this.options = [];
    this.value = '';
    this.selected.emit(item);
  }
}
