import { Component, Input } from '@angular/core';
import { FormProvider } from '../form-provider';

import { FieldMarketplace } from '@topseller/core';
import { FormControl } from '@angular/forms';
import { AccountForm } from '../types';

@Component({
  selector: 'ts-marketplace-field',
  templateUrl: './marketplace-fields.component.html',
  styleUrls: ['./marketplace-fields.component.scss'],
})
export class TsMarketplaceFieldsComponent {
  @Input() public field!: FieldMarketplace;

  @Input()
  public index = 0;

  @Input()
  public isEdit!: boolean;

  @Input()
  public isNew!: boolean;

  @Input()
  public error!: Array<string> | undefined;

  public formControl: FormControl | null = this.field.identifier
    ? (this.formProvider.getForm().controls[
        this.field.identifier
      ] as FormControl)
    : null;

  constructor(private formProvider: FormProvider<AccountForm>) {}
}
