import { Component, Input } from '@angular/core';

@Component({
  selector: 'ts-error-text',
  templateUrl: './error-text.component.html',
  styleUrls: ['./error-text.component.scss'],
})
export class ErrorTextComponent {
  @Input() errorText = '';
}
