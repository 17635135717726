import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Configuration } from '../configuration';
import { ReportProfit } from '../model/reports/report-profit';
import { map, Observable, of, throwError } from 'rxjs';
import { Paginated } from '../model/paginated';
import { ReportProfitDetail } from '../model/reports/report-profit-detail';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';

@Injectable()
// TODO REMOVE!!!! USE ReportProductService
export class ReportProfitabilityApiService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  createReport(report: ReportProfit): Observable<ReportProfit> {
    return this.httpClient.post<ReportProfit>(
      `${this.basePath}/api/v1/report/profits`,
      report
    );
  }

  getReportDetails(
    reportId: string,
    limit?: number,
    offset?: number
  ): Observable<Paginated<ReportProfitDetail>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    return this.httpClient.get<Paginated<ReportProfitDetail>>(
      `${this.basePath}/api/v1/report/profits/${encodeURIComponent(
        String(reportId)
      )}/detail`,
      { params: queryParameters }
    );
  }
}
