/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.0505.1215
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BatchDelete {
  countAccepted?: number;
  countDeclined?: number;
  errors?: Array<string>;
  hash?: string;
}
