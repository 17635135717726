import { Component, Input } from '@angular/core';
import { NavLink } from '../model/models';

@Component({
  selector: 'ts-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent {
  @Input() public links: NavLink[] = [];
}
