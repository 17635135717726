import { Directive } from '@angular/core';

import { TsEntityListComponent } from './entity-list.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ts-entity-list[add],[addItem]',
})
export class TsEntityListDirective {
  constructor(entityList: TsEntityListComponent) {
    entityList.canAdd = true;
  }
}
