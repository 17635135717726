import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Paginated } from '../model/paginated';
import { CommissionerDemand } from '../model/commissionerDemand';
import { Observable } from 'rxjs';
import { DocumentUntrashService } from '../../common/services';

@Injectable()
export class CommissionerDemandsService
  implements DocumentUntrashService<CommissionerDemand>
{
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'http://denis.dev.topseller.ru';

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getList(
    limit?: number,
    offset?: number,
    filter?: string,
    search?: string,
    sortName?: string,
    sortDir?: string
  ) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    if (sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir);
    }

    return this.httpClient.get<Paginated<CommissionerDemand>>(
      `${this.basePath}/api/v1/com-agent-demands?${filter ?? ''}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: this.defaultHeaders,
      }
    );
  }

  create(
    commissionerDemand: CommissionerDemand
  ): Observable<CommissionerDemand> {
    return this.httpClient.post<CommissionerDemand>(
      `${this.basePath}/api/v1/com-agent-demands`,
      commissionerDemand,
      { withCredentials: this.configuration.withCredentials }
    );
  }

  getById(id: string): Observable<CommissionerDemand> {
    return this.httpClient.get<CommissionerDemand>(
      `${this.basePath}/api/v1/com-agent-demands/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  update(
    commissionerDemand: CommissionerDemand
  ): Observable<CommissionerDemand> {
    return this.httpClient.patch<CommissionerDemand>(
      `${this.basePath}/api/v1/com-agent-demands`,
      commissionerDemand,
      { withCredentials: this.configuration.withCredentials }
    );
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(
      `${this.basePath}/api/v1/com-agent-demands/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  public getUntrash(id: string) {
    return this.httpClient.get<CommissionerDemand>(
      `${this.basePath}/api/v1/trash/entity/${id}/restore`
    );
  }
}
