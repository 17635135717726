export interface Invoice {
  id?: string;
  number?: string;
  createdAt?: number;
  datedAt?: number;
  amount?: number;
  isPayed?: boolean;
  paymentSystem?: string;
  description?: string;
  url?: string;
  tariffs: Tariff[];
  promoCodeString?: string;
  isRecurrent?: boolean;
  status?: number;
  restrictions?: Restriction;
}

export interface BillingProduct {
  id: string;
  identifier: string;
  name: string;
  sort: number;
}


export interface BillingPeriod {
  name: string;
}

export interface PayloadSubscription {
  expiresAt?: Date;
  id?: string;
  period: Period;
  product: BillingProduct;
  tariff: Tariff;
}

export interface Period {
  count: number;
  id: number;
  name: string;
  unit: string;
}

export interface Tariff {
  id: string;
  identifier?: string;
  billingProduct?: BillingProduct;
  billingPeriod?: BillingPeriod;
  name?: string;
  price?: number;
  priceSale?: number;
  renewAllowed?: boolean;
}

export interface Restriction {
  numberOfGoods: number;
  startsPerDay: number;
}

export interface BillingRestriction {
  id?: string;
  billingProduct?: BillingProduct;
  identifier?: string;
  name?: string;
  type?: string;
  isCalculated?: boolean;
  refreshPeriod?: string;
}

export interface BillingRestrictionValue {
  id?: string;
  billingRestriction?: BillingRestriction;
  value?: string;
}

export interface BillingAccess {
  id?: string;
  billingProduct?: BillingProduct;
  identifier?: string;
  name?: string;
  billingRestrictionValues?: Array<BillingRestrictionValue>;
}

export interface BillingTariff {
  id?: string;
  identifier?: string;
  billingProduct?: BillingProduct;
  billingAccess?: BillingAccess;
  billingPeriod?: BillingPeriod;
  name?: string;
  innerName?: string;
  price?: number;
  priceSale?: number;
  isRenewable?: boolean;
  renewBefore?: number;
  renewAllowed?: boolean;
  isCalculated?: boolean;
  formula?: string;
}

export interface BillingPayment {
  id?: string;
  number?: string;
  status?: number;
  isRecurrent?: boolean;
  createdAt?: Date;
  resolvedAt?: Date;
  amount?: number;
  isPayed?: boolean;
  paymentSystem?: string;
  paymentSystemId?: string;
  promoCodeString?: string;
  description?: string;
  tariffs?: Array<BillingTariff>;
  url?: string;
  restrictions?: Array<string>;
  datedAt?: Date | string;
}

export interface BillingAccountRestrictions {
  accountId?: string;
  userId?: string;
  product?: string;
  restrictions?: { [key: string]: any; };
  currentRestrictions?: { [key: string]: any; };
  limits?: { [key: string]: any; };
  tariff?: string;
  tariffName?: string;
  tariffActive?: boolean;
  expiresAt?: Date;
  renewAllowed?: boolean;
  isRecurrent?: boolean;
  lastPayment?: BillingPayment;
  id?: string;
  hash?: string;
  amount?: number
}

export interface PaymentHistoryItem {
  id?: string;
  status?: number;
  datedAt: string;
  createdAt: string;
  resolvedAt?: string;
  amount: number;
  isPayed?: boolean;
  isOnline: boolean;
  inn: string;
  email: string;
  url: string;
  description: string;
  isDeleted?: boolean;
}
