/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1205.1237
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { HubEntity } from '../variables';
import { Record } from "@topseller/core";

export interface Status extends Record {
  type: HubEntity;
  color: string;
  sort?: number;
}
