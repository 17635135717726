import { AbstractControl, FormGroup } from '@angular/forms';

export abstract class FormProvider<
  T extends { [K in keyof T]: AbstractControl<any, any> } = any
> {
  protected _isLoading = false;

  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  abstract getForm(): FormGroup<T>;
}
