import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown-v2',
  templateUrl: './dropdown-v2.component.html',
  styleUrls: ['./dropdown-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownV2Component),
      multi: true,
    },
  ],
})
export class DropdownV2Component implements ControlValueAccessor {
  @Input() title = '';
  @Input() options: string[] | null | undefined = [];
  @Input() tooltip: string | undefined = undefined;
  @Input() edit: boolean | undefined = false;

  value = '';
  disabled = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};

  writeValue(value: string = ''): void {
    this.value = value;
  }
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
