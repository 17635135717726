import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TsDay, TsMonth, TsTime } from '../common';
import { TsAbstractNullableControl } from '@topseller/cdk/abstract';
import { getNativeFocused } from '../utils';

type Maybe<T> = T | null;
export type ControlSize = 'large' | 'small' | 'cell';

@Component({
  selector: 'ts-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent extends TsAbstractNullableControl<
  Maybe<TsDay>
> {
  @Input() label = '';

  @Input() readonly = false;

  private initialMonth = TsMonth.currentMonth();

  public isVisible = false;

  @ViewChild('input', { read: ElementRef, static: true })
  public input?: ElementRef<HTMLInputElement>;

  @HostBinding(`attr.data-size`)
  @Input()
  size: ControlSize = 'large';

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return (!!element && node.contains(element)) || this.isVisible;
  }

  public get computedMonth(): TsMonth {
    return this.value || this.initialMonth;
  }

  public get nativeValue(): string {
    return this.input?.nativeElement.value || ``;
  }

  public get computedValue(): string {
    const { value, nativeValue } = this;
    if (!value) {
      return nativeValue;
    }
    return this.getDateString(value);
  }

  constructor(
    private elementRef: ElementRef,
    @Self() @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  @HostListener('click') public onClick() {
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    this.input?.nativeElement.focus();
  }

  public override writeValue(rawValue: any): void {
    const value = this.fromControlValue(rawValue);
    super.writeValue(value);
  }

  public onDayClick(value: TsDay): void {
    this.updateValue(value);
    this.isVisible = false;
  }

  public override registerOnChange(
    onChange: (value: [Maybe<TsDay>, Maybe<TsTime>] | unknown) => void
  ): void {
    this.onChange = (componentValue: Maybe<TsDay>) => {
      onChange(this.toControlValue(componentValue));
    };
  }

  public onFocused(): void {
    const [, time] = this.nativeValue.split(' ');
    if (!time) {
      return;
    }
    const parsedTime = TsTime.fromString(time);
  }

  public onValueChange(value: string): void {
    this.updateOpen(false);

    if (value.length < 'dd.MM.yyyy'.length) {
      this.updateValue(null);
      return;
    }
    const parsedDate = TsDay.normalizeParse(value);

    this.updateValue(parsedDate);
  }

  public onActiveZone(active: boolean) {
    if (!active && this.isVisible) {
      this.isVisible = false;
    }
  }

  private getDateString(date: TsDay | string): string {
    const dateString = date instanceof TsDay ? date.toString() : date;
    return `${dateString}`;
  }

  private fromControlValue(controlValue: string): Maybe<TsDay> {
    return controlValue ? TsDay.normalizeParse(controlValue) : null;
  }

  private toControlValue(date: Maybe<TsDay>): string {
    if (date) {
      const { day, month, year } = date;
      return new Date(year, month, day).toISOString();
    }

    return '';
  }

  private updateOpen(value: boolean): void {
    if (this.isVisible === value) {
      return;
    }
    this.isVisible = value;
  }

  clear() {
    this.updateValue(null);
  }
}
