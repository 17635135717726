import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductBaseService {
  sections: string[] = [
    'Товары и услуги',
    'Оприходования',
    'Списания',
    'Инвентаризации',
    'Перемещения',
  ];

  selectedSection$ = new BehaviorSubject<string>('Товары и услуги');

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const params = event.url.split('/');

        if (params[params.length - 1] === 'write-off') {
          this.selectedSection$.next('Списания');
        } else {
          this.selectedSection$.next('Товары и услуги');
        }
      }
    });
  }

  selectSection(): void {
    this.selectedSection$.pipe(take(1)).subscribe((section: string) => {
      switch (section) {
        case 'Списания':
          this.router.navigateByUrl('/hub/products/write-off');
          break;
        default:
          this.router.navigateByUrl('/hub/products/goods');
          break;
      }
    });
  }
}
