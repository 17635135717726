import { Component, Input, OnInit } from '@angular/core';

import { ChangeHistory } from './changes-history.mock';

@Component({
  selector: 'app-changes-history',
  templateUrl: './changes-history.component.html',
  styleUrls: ['./changes-history.component.scss'],
})
export class ChangesHistoryComponent {
  @Input() id = '';
  changesList: ChangeHistory[] = [];
}
