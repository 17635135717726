/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1205.1237
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChangeableRecord } from "@topseller/core";


export interface Project extends ChangeableRecord {
  note: string;
  isDefault?: boolean;
}
