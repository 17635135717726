<div class="row">
  <div class="col">
    <div ngbDropdown class="d-inline-block h-full dropdownWrapper">
      <button
        type="button"
        class="btn bordered-light {{
          buttonType ? buttonType : 'bordered-light'
        }}"
        id="dropdownBasic1"
        ngbDropdownToggle
        [ngStyle]="{
          padding,
          'minWidth': width,
          height,
          background
        }"
      >
        {{ title }}
        <svg
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292894L7.70711 6.29289L6.29289 7.70711Z"
            fill="#A5AEC0"
          />
        </svg>
      </button>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
        class="w-full options"
      >
        <div
          class="cursor-pointer"
          *ngFor="let option of options"
          ngbDropdownItem
          (click)="onChange(option)"
        >
          {{ option && option.name ? option.name : option }}
        </div>
      </div>
    </div>
  </div>
</div>
