<ng-container *ngIf="formControl">
  <app-title
    [formControl]="formControl"
    [title]="field.title!"
    [readonly]="(!isNew && !isEdit) || (field.isBlocked! && isEdit)"
  ></app-title>
  <span
    *ngIf="error"
    class="ts-icon text-normal ts-icon-actions-info-circle icon-properties"
    [tsHint]="hintMasterPhrase"
  ></span>
  <ng-template #hintMasterPhrase>
    <span style="font-size: 12px; line-height: 12px; color: #e56b6b">
      {{ error![0] }}
    </span>
  </ng-template>
</ng-container>
