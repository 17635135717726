import { Component, Input } from '@angular/core';

const productTypes: { [key: string]: string } = {
  set: 'комплект',
  product: 'товар',
  service: 'услуга',
};

@Component({
  selector: 'ts-product-type-image',
  templateUrl: './product-type-image.component.html',
})
export class ProductTypeImageComponent {
  @Input()
  public type = 'product';

  public getImage() {
    return `/assets/img/hub/product-types/${this.type?.toLowerCase()}.svg`;
  }

  public getText() {
    return productTypes[this.type?.toLowerCase()];
  }
}
