/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0222.1716
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ModifiedBy } from "@topseller/core";

export interface Comment {
  id?: string;
  entity?: string;
  entityId?: string;
  content?: string;
  data?: string[];
  files?: string[];
  createdBy?: ModifiedBy;
  createdAt?: Date;
  modifiedAt?: Date;
}
