import { Store } from '@ngrx/store';
import { NgControl } from '@angular/forms';

import { TsAbstractControl } from '@topseller/cdk/abstract';

import {
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  Output,
  EventEmitter, HostBinding, Input,
} from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CustomField, HubEntity } from '../../../data';
import { selectFormFields } from '../../../store';
import { ENTITY_NAME } from '../../tokens';

enum CustomFieldTypes {
  TEXT = 'text',
  LINK = 'link',
  NUMBER = 'number',
  FRACTIONAL = 'fractional',
  BOOLEAN = 'boolean',
  DATETIME =  'datetime'
}

@Component({
  selector: 'ts-extra-fields',
  templateUrl: './extra-fields.component.html',
  styleUrls: ['./extra-fields.component.scss'],
})
export class ExtraFieldsComponent extends TsAbstractControl<
  {
    customField: { id: string; type: string };
    value: string | boolean;
  }[]
> {
  public numberMask = '0*';
  public priceMask = '0*.00';

  public CustomFieldTypes = CustomFieldTypes;

  @Output() public checkFields: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public formFields$: Observable<CustomField[]> = this.store
    .select(selectFormFields(this.entity))
    .pipe(tap((fields: CustomField[]) => this.checkFields.emit(!!fields)));

  constructor(
    @Optional() @Inject(ENTITY_NAME) public readonly entity: HubEntity,
    private store: Store,
    @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  public getStringValue(fieldId: string): string {
    return (this.getValue(fieldId) || '').toString();
  }

  private getValue(fieldId: string): string | boolean | null {
    return (
      this.value.find(({ customField }) => fieldId === customField.id)?.value ||
      null
    );
  }

  public valueChanges(field: CustomField, value: string | boolean): void {
    const existingField = this.value?.findIndex(
      ({ customField }) => customField.id === field.id
    );

    let newValue = { customField: { id: field.id, type: field.type }, value };

    //костыль работы с датами где поменял формат дд.мм.гггг на мм.дд.гггг
    if(field.type === CustomFieldTypes.DATETIME && typeof value === "string") {
      const [datePart, timePart] = value.split(" ");
      const [day, month, year] = datePart.split(".");
      const swappedDateStr =  `${month}.${day}.${year} ${timePart}`;

      newValue = { ...newValue, value: swappedDateStr}
    }
    if(field.type === CustomFieldTypes.FRACTIONAL) {
      newValue = {
        ...newValue,
        value: value.toString()
      }
    }

    if(field.type === CustomFieldTypes.BOOLEAN) {
      newValue = {
        ...newValue,
        value: value ? '' : '1'
      }
    }

    if (existingField < 0) {
      this.updateValue([...this.value, newValue]);
    } else {
      const newArray = Object.assign([], this.value, {
        [existingField]: newValue,
      });
      this.updateValue(newArray);
    }
  }

  protected getFallbackValue(): {
    customField: { id: string; type: string };
    value: string | boolean;
  }[] {
    return [];
  }
}
