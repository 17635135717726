/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1205.1237
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Image } from './image';
import { HubDocument, Project } from './models';
import { OrderProduct } from './order-product';
import { Record } from "@topseller/core";
import { Status } from './status';
import { Tag } from './tag';
import { Counterparty, Organization, Warehouse } from "@topseller/core";

export interface Order extends HubDocument {
  isReserve: boolean;
  isReceive: boolean;
  plannedAt: number;
  organization?: Organization;
  counterparty?: Counterparty;
  project?: Project;
  address?: string;
  reserved?: boolean;
  shipped?: boolean;
  _return?: boolean;
  amount: number;
  amountWithVat: number;
  customFields: { id: string; value: string | boolean }[];
  amountPaid: number;
  amountDemanded: number;
  amountReturn?: number;
  amountToPay?: number;
  amountRecalled?: number;
  deliveryAddress?: string;
  deliveryComment?:string;
  isVat: boolean;
  note?: string;
  status?: Status;
  isPriceIncludesVat: boolean;
  tags?: Array<Tag>;
  products: Array<OrderProduct>;
  images?: Array<Image>;
}
