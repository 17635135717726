<nav class="navbar navbar-expand-sm navbar-light header-navbar transparent">
  <ts-key-info></ts-key-info>
  <ts-user-info></ts-user-info>
</nav>

<div class="d-flex align-items-center justify-content-center w-100 gap-2">
  <img src="/assets/img/app-logo.svg" alt="TOPSELLER" width="auto" height="62px" class="" />
  <h1>Экосистема</h1>
</div>

<div class="plates__wrap">
  <div class="plates__item hub" routerLink="/hub">
    <p>Hub <span>(beta)</span></p>
    <span>Ведите грамотный учёт на своём складе и узнайте, сколько вы
      заработали</span>
  </div>
  <div class="plates__item connect" (click)="redirectToConnect()">
    <p>Connect</p>
    <span>Интеграция с 5 Маркетплейсами</span>
  </div>
  <div class=" plates__item pim" routerLink="/pim">
    <p>PIM</p>
    <span>Управление контентом на Ozon и Wildberries</span>
  </div>
  <div class="plates__item dropship" routerLink="/dropship">
    <p>Dropship</p>
    <span>Оптимизация поставок товара со складов поставщика</span>
  </div>
  <div class="plates__item repricer" (click)="redirectToRepricer()">
    <p>Repricer</p>
    <span>Продажи по лучшей цене автоматически</span>
  </div>
  <div class="plates__item ads" routerLink="/wb-ads">
    <p>Ads</p>
    <span>Автоматизация рекламы на Wildberries по реальным ставкам</span>
  </div>
  <div class="plates__item ai" routerLink="/ai">
    <p>AiTools <span>(beta)</span></p>
    <span>Автоматизированные ответы на отзывы с помощью нейросети</span>
    <br />
    <span>Powered by ChatGPT</span>
  </div>
  <div class="plates__item mobile" (click)="redirectToMobile()">
    <p>Mobile</p>
    <span>Первое iOS приложение для селлеров</span>
  </div>

  <div class="plates__item" style="position: relative; cursor: default">
    <div style="width: 10px; height: 10px; position: absolute; cursor: pointer" routerLink="/hub"></div>
    <p>Скоро</p>
  </div>
</div>
