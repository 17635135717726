import { Directive, HostListener, Inject, Input } from '@angular/core';

import { ComponentType } from "@angular/cdk/overlay";
import { MatDialog } from "@angular/material/dialog";
import { DATA_LIST_HOST, DataListHost } from "@topseller/ui";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[addItem]',
})
export class TsAddItemDirective<T> {
  @Input() addItem?: ComponentType<T>
  constructor(
    private matDialog: MatDialog,
    @Inject(DATA_LIST_HOST) private readonly host: DataListHost<T>,
  ) { }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();
    if (!this.addItem) return;
    const dialogRef = this.matDialog.open(this.addItem, {
      disableClose: true,
      backdropClass: 'ts-modal-backdrop',
      panelClass: ['ts-modal-panel','ts-modal-panel-full', 'ts-modal-panel-full-height' ],
      width: '100vw',
      maxWidth: '100vw',
      id: 'ts-modal',
      data: {
        isModal: true
      }
    });

    dialogRef.afterClosed().subscribe(
      item => this.host.handleOption(item)
    );
  }
}
