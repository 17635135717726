export interface ReportTotals extends Record<string, number> {
  amount: number;
  amountSale: number;
  available: number;
  reserved: number;
  stock: number;
  warehouses?: any;
}

export interface ReportWarehouses {
  id: string;
  available: number;
  stock: number;
  name: string;
  reserved: number;
}


