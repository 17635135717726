<ts-base-input
  #parent="activeZone"
  (activeZoneChange)="onActiveZone($event)"
  [dropdown]="dropdownTemplate"
  [show]="isVisible"
  mask="d0.M0.0000"
  [value]="computedValue"
  [label]="label"
  [readonly]="readonly"
  trailingIcon="ts-icon-time-calendar"
  (onClear)="clear()"
>
</ts-base-input>

<ng-template #dropdownTemplate>
  <ts-calendar
    [activeZoneParent]="parent"
    [value]="value ? value : null"
    (dayClick)="onDayClick($event)"
  ></ts-calendar
></ng-template>
