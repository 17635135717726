/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// import { BarcodeModel } from './barcodeModel';
// import { BrandModel6 } from './brandModel6';
// import { ProductCustomFieldModel5 } from './productCustomFieldModel5';
// import { ProductGroupModel4 } from './productGroupModel4';
// import { ProductImageModel } from './productImageModel';
// import { ProductModificationValueModel } from './productModificationValueModel';
// import { ProductSetItemModel } from './productSetItemModel';

// TODO Fix types

export interface ProductSet {
  productSetItems?: any; // Array<ProductSetItemModel>;
  id?: string;
  productGroup?: any; //ProductGroupModel4;
  name?: string;
  sku?: string;
  code?: string;
  vat?: number;
  isEnabled?: boolean;
  weight?: number;
  volume?: number;
  width?: number;
  height?: number;
  length?: number;
  isSet?: boolean;
  images?: any; //Array<ProductImageModel>;
  pricePurchase?: number;
  priceMin?: number;
  priceRrp?: number;
  price?: number;
  priceSale?: number;
  brand?: any; //BrandModel6;
  barcodes?: any; //Array<BarcodeModel>;
  customFields?: any; //Array<ProductCustomFieldModel5>;
  color?: string;
  productModificationValues?: any; //Array<ProductModificationValueModel>;
  hasModification?: boolean;
}
