/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.1031.0717
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MarketplaceScheme } from './marketplaceScheme';

export type SchemaType = 'fba' | 'fbo' | 'fbs' | 'fbw' | 'fby' | 'dbs' | 'adv';

export type MarketplaceSchemes = Record<SchemaType, MarketplaceScheme>;
