import { Barcode } from './barcode';
import { ProductDescription } from './product-description';
import { ProductGroup } from './product-group';
import { Record } from "@topseller/core";
import { Vat } from './vat';
import { File } from './file'
import { ProductPrice, ProductPrices } from "./product-prices";
import { ChangeableRecord, Warehouse } from "@topseller/core";
import { CustomFieldValue } from "./customFieldValue";

export interface Product extends ChangeableRecord {
  productGroup?: ProductGroup;
  sku: string;
  supplierSku: string;
  code: string;
  vat: number;
  vatType: Vat;
  isEnabled?: boolean;
  weight?: number;
  volume?: number;
  width?: number;
  height?: number;
  length?: number;
  isSet?: boolean;
  images?: Images[];
  pricePurchase?: number;
  priceMin?: number;
  priceRrp?: number;
  price?: number;
  priceSale?: number;
  productPriceGroups?: ProductPrices[];
  brand: Brand;
  descriptions?: Array<ProductDescription>;
  barcodes?: Barcode[];
  customFields?: Array<CustomFieldValue>;
  color?: string;
  productModificationValues: ProductModifValues[];
  productModifications?: Product[];
  isModification: boolean;
  parent?: Product;
  hasModification?: boolean;
  active?: boolean;
  type: string;
  image: string;
  productSetItems?: ProductSetItems[];
  isArchived: boolean;
  isWeighable: boolean;
  isTrashed: boolean;
  readonly stocks?: number | null;
  stockWarehouseSum?: number | null;
  stockWarehouseEqual?: number | null;
  stockWarehouseCountNotifications?: ProductStocksWarehouse[];
  reserved?: number;
  files?: File[];
}


export interface ProductSetItems {
  product: Product;
  quantity: number;

}

export interface ProductStocksWarehouse {
  warehouse: Warehouse;
  count: number;
}

export interface ProductModifValues {
  id?: string;
  value: string;
  values: string[];
  attribute: Record;
}

export interface Images {
  id?: string;
  isDefault?: boolean;
  url: string;
}

export interface Brand {
  id?: string;
  isPopular?: boolean;
  name?: string;
}

export interface ProductCatalogItem extends Product{
  quantity: number;
}

export interface ProductPriceWithProduct extends ProductPrice{
  product:Product;
}
