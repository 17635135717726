/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1202.1329
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Image {
  id?: number;
  file?: string;
  isDefault?: boolean;
}
