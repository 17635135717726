<div ngbDropdown class="dropdown-v2">
  <p class="label-placeholder" [class.focused]="value">{{ title }}</p>
  <button ngbDropdownToggle id="dropdown-v2">
    <span *ngIf="value">{{ value }}</span>
    <div>
      <img src="assets/img/ui/wb-ads/arrow.svg" alt="" />
      <app-attention-tooltip
        *ngIf="tooltip"
        [tooltip]="tooltip"
      ></app-attention-tooltip>
      <img *ngIf="edit" src="assets/img/ui/table/pen.svg" alt="" />
    </div>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdown-v2" class="dropdown-values">
    <div
      *ngFor="let option of options"
      (click)="onChange(option); value = option"
      ngbDropdownItem
    >
      {{ option }}
    </div>
  </div>
</div>
