import {Record as TsRecord} from "@topseller/core";

export interface BatchActionTemplate {
  isCommitted?: boolean;
  isReserve?: boolean;
  isArchived?: boolean;
  status?: TsRecord;
  productGroup?: TsRecord;
}

export interface TemplateRequest {
  itemsId: string[];
  template: BatchActionTemplate;
}
