import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { WINDOW } from '@topseller/cdk/common';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  constructor(@Inject(WINDOW) private window: Window, private router: Router) {}

  redirectToConnect() {
    // if (environment.production) {
    //   window.open(`${environment.connectUrl}`, '_blank');
    // } else {
    this.router.navigate(['connect']);
    // }
  }

  redirectToMobile() {
    this.window.open('https://mobile.topseller.ru/');
  }

  redirectToRepricer() {
    // if (environment.production) {
    //   this.window.open('https://ar.topseller.ru/');
    // } else {
    this.router.navigate(['repricer']);
    // }
  }
}
