import { BankService } from './bank.service';
import { BarcodeTypeService } from './barcodeType.service';
import { BaseDataService } from './base-data.service';
import { BrandService } from './brand.service';
import { CashflowService } from './cashflow.service';
import { CashflowSourceService } from './cashflow-source.service';
import { CommentService } from './comment.service';
import { CorrectionService } from './correction.service';
import { CounterpartyService } from './counterparty.service';
import { CountryService } from './country.service';
import { DepartmentService } from './department.service';
import { EmployeeService } from './employee.service';
import { ImageService } from './image.service';
import { InventoryService } from './inventory.service';
import { LinkedDocumentService } from './linked-document.service';
import { MovementService } from './movement.service';
import { OrderService } from './order.service';
import { OrganizationService } from './organization.service';
import { PaymentService } from './payment.service';
import { PostingService } from './posting.service';
import { ProductGroupService } from './product-group.service';
import { ProductStockService } from './product-stock.service';
import { ProductService } from './product.service';
import { ProjectService } from './project.service';
import {PurchaseService} from './purchase.service';
import { SecurityService } from './security.service';
import { SourceService } from './source.service';
import { StatusApiService } from './status.service';
import { UnitService } from './unit.service';
import { WarehouseGroupService } from './warehouse-group.service';
import { WarehouseService } from './warehouse.service';
import { WriteOffService } from './write-off.service';
import { ReportProductService } from './report-product.service';
import { AuditService } from './audit.service';
import { RevertService } from './revert.service';
import { DemandService } from './demand.service';
import { RecallService } from './recall.service';
import { ReceiveService } from './receive.service';
import { ReportWarehouseService } from './report-warehouse.service';
import { ReportTurnoverService } from './report-turnover.service';
import { ExternalService } from './external.service';
import { ReportAbcService } from './report-abc.service';
import { CommonBatchActionsApiService } from "./common-batch-actions-api.service";
import {PrintFormsApiService} from "./print-forms-api.service";
import { CommissionerDemandsService } from './commissioner-demands.service';

export { AuditService } from './audit.service';
export { BankService } from './bank.service';
export { BarcodeTypeService } from './barcodeType.service';
export { BaseDataService } from './base-data.service';
export { BrandService } from './brand.service';
export { CashflowService } from './cashflow.service';
export { CashflowSourceService } from './cashflow-source.service';
export { CommentService } from './comment.service';
export { CorrectionService } from './correction.service';
export { CounterpartyService } from './counterparty.service';
export { CountryService } from './country.service';
export { DepartmentService } from './department.service';
export { EmployeeService } from './employee.service';
export { ImageService } from './image.service';
export { InventoryService } from './inventory.service';
export { LinkedDocumentService } from './linked-document.service';
export { MovementService } from './movement.service';
export { OrderService } from './order.service';
export { OrganizationService } from './organization.service';
export { PaymentService } from './payment.service';
export { PostingService } from './posting.service';
export { ProductStockService } from './product-stock.service';
export { ProductService } from './product.service';
export { ProjectService } from './project.service';
export { PurchaseService } from './purchase.service';
export { SecurityService } from './security.service';
export { SourceService } from './source.service';
export { UnitService } from './unit.service';
export { StatusApiService } from './status.service';
export { TransferService } from './transfer.service';
export { WarehouseService } from './warehouse.service';
export { WriteOffService } from './write-off.service';
export { ReportProductService } from './report-product.service';
export { RevertService } from './revert.service';
export { DemandService } from './demand.service';
export { RecallService } from './recall.service';
export { ReceiveService } from './receive.service';
export { ReportWarehouseService } from './report-warehouse.service';
export { ReportTurnoverService } from './report-turnover.service';
export { ExternalService } from './external.service';
export { ReportAbcService } from './report-abc.service';

export const APIS = [
  AuditService,
  BankService,
  CommentService,
  CorrectionService,
  CashflowSourceService,
  EmployeeService,
  ProductService,
  ProductGroupService,
  OrganizationService,
  CounterpartyService,
  DepartmentService,
  PurchaseService,
  InventoryService,
  ImageService,
  MovementService,
  WarehouseService,
  OrderService,
  ProjectService,
  PaymentService,
  StatusApiService,
  ReportWarehouseService,
  ReportTurnoverService,
  WriteOffService,
  PostingService,
  LinkedDocumentService,
  BarcodeTypeService,
  CountryService,
  BrandService,
  UnitService,
  BaseDataService,
  SecurityService,
  SourceService,
  WarehouseGroupService,
  CashflowService,
  ProductStockService,
  ReportProductService,
  RevertService,
  DemandService,
  RecallService,
  ReceiveService,
  ExternalService,
  ReportAbcService,
  CommonBatchActionsApiService,
  PrintFormsApiService,
  CommissionerDemandsService
];
