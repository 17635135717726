import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import {
  debounceTime,
  filter,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';

import { Product, Purchase, PurchaseProduct } from '../../../data';
import { CatalogsDataService } from '../../services/catalogs-data.service';
import {LoadableSelector} from "../../loadable-selector";

@Component({
  selector: 'app-catalog-table',
  templateUrl: './catalog-table.component.html',
  styleUrls: ['./catalog-table.component.scss'],
})
export class CatalogTableComponent
  extends LoadableSelector
  implements OnInit
{
  private destroy$: Subject<void> = new Subject<void>();

  @Input() formProducts: any[] = [];
  @Output() productsChange = new EventEmitter<Product[]>();

  public isTableIdle = true;
  public form: FormGroup;

  public totals$?: Observable<{ title: string; value: number }[]>;
  public linePlaceholder: FormControl = new FormControl(null);
  public search: FormControl = new FormControl(null);
  public products$?: Observable<any>;

  @ViewChild('scrollbar')
  scrollbar!: PerfectScrollbarDirective;

  public selected: string[] = [];

  public tableHeaders: any[] = [
    { name: 'checkbox', width: 60 },
    { name: 'product', title: 'Товар' },
    { name: 'sku', title: 'Артикул', width: 200 },
    { name: 'quantity', title: 'Количество', width: 200 },
    { name: 'price', title: 'Цена', width: 200 },
    { name: 'amount', title: 'Сумма', width: 200 },
    { name: 'stock', title: 'Остаток', width: 200 },
    { name: 'weight', title: 'Вес (г)', width: 200 },
    { name: 'volume', title: 'Объем (мл)', width: 200 },
  ];

  private get products(): FormArray {
    return this.form.controls['products'] as FormArray;
  }

  public get productControls(): FormGroup[] {
    return this.products.controls as FormGroup[];
  }

  private get productGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      product: new FormControl(null, Validators.required),
      quantity: new FormControl(1, Validators.required),
      price: new FormControl(null),
      vat: new FormControl(20),
    });
  }

  constructor(
    catalogsDataService: CatalogsDataService,
    private activatedRoute: ActivatedRoute
  ) {
    super(catalogsDataService);

    this.form = new FormGroup({
      products: new FormArray([]),
    });
  }

  get allSelected(): boolean {
    return (
      this.products.value.length &&
      this.products.value.length === this.selected.length
    );
  }

  public isSelected(id: string): boolean {
    return this.selected.includes(id);
  }

  public toggleAll(value: boolean): void {
    if (!value) {
      this.selected = [];
    } else {
      this.selected = this.products.value.map(
        (product: { id: string }) => product.id
      );
    }
  }

  public select(value: boolean, id: string): void {
    const idx = this.selected.indexOf(id);

    if (!value && idx > -1) {
      this.selected.splice(idx, 1);
    } else if (value && idx === -1) {
      this.selected.push(id);
    }
  }

  public deleteLines(): void {
    for (let i = 0; i < this.products.value.length; i++) {
      const { id } = this.products.value[i].product;
      const selected = this.selected.includes(id);

      if (selected) {
        this.products.removeAt(i);
      }
    }

    this.selected = [];
  }

  clearFormArray(): void {
    while (this.products.length !== 0) {
      this.products.removeAt(0);
    }
  }

  addToFormArray(): void {
    this.formProducts.forEach((product: PurchaseProduct) => {
      this.products.push(
        new FormGroup({
          id: new FormControl(product.id),
          product: new FormControl(product.product, Validators.required),
          quantity: new FormControl(product.quantity, Validators.required),
          price: new FormControl(product.price, Validators.required),
          vat: new FormControl(product.product?.vat || 20),
        })
      );
    });
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      this.clearFormArray();

      if (data['id'] !== 'create') {
        this.formProducts.length && this.addToFormArray();
      }
    });

    this.products.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((productsArray) => {
        this.productsChange.emit(productsArray);
      });

    this.handleNewLine();

    this.totals$ = this.products.valueChanges.pipe(
      startWith(this.products.value),
      map(this.calculateTotals),
      map((value: number) => [{ title: 'Сумма заказа', value: value }])
    );

    this.products$ = this.search.valueChanges.pipe(
      startWith(''),
      map((search: string) => search.toLowerCase()),
      debounceTime(300),
      takeUntil(this.destroy$),
      switchMap((search: string) =>
        this.products.valueChanges.pipe(
          map(() => this.productControls),
          startWith(this.productControls),
          map((formArr: FormGroup[]) =>
            formArr.filter((group: FormGroup) => {
              const { name, sku } = group.get('product')?.value;
              return (
                name.toLowerCase().includes(search) ||
                sku.toLowerCase().includes(search)
              );
            })
          )
        )
      )
    );
  }

  private handleNewLine(): void {
    this.linePlaceholder.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((value: Product) => !!value)
      )
      .subscribe((value: Product) => {
        const newGroup = this.productGroup;
        this.linePlaceholder.setValue(null);

        this.isTableIdle = false;
        newGroup.controls['product'].setValue(value);
        this.products.push(newGroup);
        this.search.setValue('');
      });
  }

  public lineAdded(): void {
    if (!this.isTableIdle) {
      this.isTableIdle = true;
      //this.scrollbar.scrollToBottom();
    }
  }

  public isInvalid(form: FormGroup, controlName: string): boolean | null {
    return (
      (form.get(controlName)!.errors && form.get(controlName)!.dirty) ||
      (controlName === 'quantity' && !form.get(controlName)!.value)
    );
  }

  private calculateTotals = (value: any): number => {
    return value.reduce(
      (acc: number, curr: any) => acc + curr.quantity * curr.price,
      0
    );
  };

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
