<div class="label-input">
  <input
    type="text"
    placeholder="Введите отдел..."
    [(ngModel)]="value"
    (ngModelChange)="value && searchItem()"
  />

  <div class="label-input__options" *ngIf="value.length">
    <ts-dot-loader *ngIf="isLoading"></ts-dot-loader>
    <h5 *ngIf="!options.length && !isLoading">Отдел не найден</h5>
    <p *ngFor="let option of options" (click)="selectItem(option)">
      {{ option.name }}
    </p>
  </div>
</div>
