/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.1128.1348
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Product } from '../product';
import { ReportTurnover } from './report-turnover';

export interface ReportTurnoverDetail {
  id: string;
  reportTurnover?: ReportTurnover;
  product?: Product;
  productImage?: string;
  name?: string;
  sku?: string;
  supplierSku?: string;
  startCount?: number;
  startSum?: number;
  receiveCount?: number;
  receiveSum?: number;
  expenseCount?: number;
  expenseSum?: number;
  endCount?: number;
  endSum?: number;
}
