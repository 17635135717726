import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, fromEvent, map, switchMap, takeUntil, tap } from 'rxjs';
import { TS_COLUMN_RESIZE } from '../../table-headers/constants';

const MIN_WIDTH = 50;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tsHeader]',
  template: '<ng-content></ng-content><div class=resizer #resizer></div>',
  styleUrls: ['./table-header.component.scss'],
})
export class TsTableHeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() public tsHeader?: string;

  @ViewChild('resizer', { read: ElementRef, static: true })
  public resizer?: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) {}

  public ngOnInit() {
    const { nativeElement } = this.elementRef;

    fromEvent<PointerEvent>(this.resizer?.nativeElement, 'pointerdown')
      .pipe(
        tap((event: PointerEvent) => {
          event.stopPropagation();
          event.preventDefault();
        }),
        takeUntil(this.destroy$),
        switchMap(({ screenX }: PointerEvent) => {
          const { width }: DOMRect = nativeElement.getBoundingClientRect();

          return fromEvent<PointerEvent>(this.document, 'pointermove').pipe(
            takeUntil(fromEvent(this.document, 'pointerup')),
            map((event: PointerEvent) => {
              const offset = screenX - event.screenX;

              return width - offset < MIN_WIDTH ? MIN_WIDTH : width - offset;
            })
          );
        })
      )
      .subscribe((event) => {
        this.ngZone.runOutsideAngular(() => {
          const { style } = nativeElement;

          nativeElement.dispatchEvent(
            new CustomEvent(TS_COLUMN_RESIZE, {
              bubbles: true,
              detail: { column: this.tsHeader, width: event },
            })
          ),
            requestAnimationFrame(() => {
              style.width = `${event}px`;
              style.flexBasis = `${event}px`;
            });
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
