<img
  [src]="getImage()"
  alt="product-image"
  [attr.width]="width"
  [attr.height]="height"
  style="object-fit: cover"
  [tsHint]="imageHover"
/>

<ng-template #imageHover>
  <img
    *ngIf="images?.length"
    width="120px"
    height="120px"
    [src]="image"
    style="object-fit: cover"
  />
</ng-template>
