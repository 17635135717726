/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.1128.1348
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Project } from '../project';
import { CustomField } from '../customField';
import { ReportProductOrGroup } from './report-product-or-group';
import { Counterparty, Organization, Warehouse } from "@topseller/core";

export interface ReportTurnover {
  id: string;
  searchText?: string;
  datetimeFrom?: Date;
  datetimeTo?: Date;
  isDisplayProducts?: boolean;
  reportProductOrGroups?: Array<ReportProductOrGroup>;
  warehouse?: Warehouse;
  project?: Project;
  counterparty?: Counterparty;
  supplier?: Counterparty;
  organization?: Organization;
  documentType?: string;
  reportCustomFields?: Array<CustomField>;
  // reportModificationValues?: Array<ReportModificationValue>;
  hash?: string;
  isCompleted?: boolean;
  sort?: Array<string>;
}
