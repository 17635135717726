import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullnameWithInitial',
})
export class FullnameWithInitialPipe implements PipeTransform {
  transform(fullnameArr: string[]): string {
    const lname = fullnameArr[0];
    const fname = fullnameArr[1][0] ? `${fullnameArr[1][0]}.` : '';
    const mname = fullnameArr[2][0] ? `${fullnameArr[2][0]}.` : '';

    return `${lname} ${fname} ${mname}`;
  }
}
