/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Pagination {
  offset?: number;
  limit?: number;
  total: number;
}
