<div class="bordered-light change-history">
  <div class="change-history__header">
    <svg class="history-svg" width="17" viewBox="0 0 18 18">
      <use xlink:href="/assets/img/icons/sales-icons.svg#history"></use>
    </svg>
    <h3>История изменений</h3>
  </div>

  <div class="change-history__content">
    <ng-container *ngIf="changesList.length">
      <div class="change" *ngFor="let changeHistory of changesList">
        <div
          class="change__header"
          (click)="changeHistory.isOpen = !changeHistory.isOpen"
        >
          <div
            class="change__header__arrow"
            [ngClass]="{ is_open: changeHistory.isOpen }"
          >
            <svg
              class="checkbox-arrow"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292894L7.70711 6.29289L6.29289 7.70711Z"
                fill="#A5AEC0"
              />
            </svg>
          </div>

          <div class="change__header__title">
            {{ changeHistory.title }}
          </div>
        </div>

        <div class="change__content" *ngIf="changeHistory.isOpen">
          <div>
            <div>Поле</div>
            <div>Было</div>
            <div>Стало</div>
          </div>
          <div>
            <div>{{ changeHistory.field }}</div>
            <div>{{ changeHistory.oldValue }}</div>
            <div>{{ changeHistory.newValue }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="change-history__empty" *ngIf="!changesList.length">
      <img src="/assets/img/hub/change-history/change-history-empty.svg" />
    </div>
  </div>
</div>
