import { Component, Input } from '@angular/core';
import { Images } from './';

@Component({
  selector: 'ts-table-image',
  templateUrl: './table-image.component.html',
})

export class TableImageComponent {
  @Input()
  public images: Images[] | string | undefined
  @Input()
  public width = '20px'
  @Input()
  public height = '20px'

  public image: string | undefined

  public getImage(): string {
    if (typeof this.images === 'string') {
      this.image = this.images
    } else {
      if (this.images?.length) {
        this.image = this.images[0].url
      } else {
        this.image = '/assets/img/hub/empty-conditions/empty-table-img.svg'
      }
    }
    return this.image
  }
}
