// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './interface';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://hub.dev.topseller.ru/api/v1/',
  apiKey: '',
  wbAdsUrl: 'https://wbads.dev.topseller.ru',
  hubUrl: 'https://hub.dev.topseller.ru',
  dropshipUrl: 'https://ds.dev.topseller.ru/api',
  aiUrl: 'https://ai-tools.dev.topseller.ru',
  connectUrl: 'https://connect.topseller.ru/apps/hub',
  pimUrl: 'https://content.topseller.ru/hub',
  journalUrl: 'https://topseller.vercel.app',
  rpUrl: 'https://repricer.dev.topseller.ru',
  eaUrl: 'https://externalytics.topseller.ru/v1',
  rnpUrl: 'https://rnp.dev.topseller.ru',
  roistApiKey: '015460b1fed5d50258430c5dcbb300dd',
  roistProjectId: 261361,
  frontendUrl: 'https://hub.dev.topseller.ru',
};
