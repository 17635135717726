import { Product, Vat } from '../data';
import { FormControl } from '@angular/forms';

export interface Totals {
  total?: number;
  totalQuantity?: number;
  totalWeight?: number;
  totalVolume?: number;
}

export interface TotalLine {
  title: string;
  value: number | string;
  currencyFormat?: boolean;
  visible?: boolean;
}

export interface TotalCalculationInput<T> {
  items: T[];
  vatIncluded?: boolean;
  isVat?: boolean;
}

export interface ProductLine {
  product: Product;
}

// ссылка на создание связанного документа.
export type DocumentCreationLink = {
  title: string;
  url: string;
  requiredRole?: string;
};

export interface TradeDocumentProductLineForm {
  id: FormControl<string | null>;
  product: FormControl<Product | null>;
  quantity: FormControl<number | null>;
  vatType: FormControl<Vat | null>;
  vatPrice: FormControl<number | null>;
  price: FormControl<number | null>;
  reserved: FormControl<number | null>;
  discountPricePercent: FormControl<number | null>;
  demanded: FormControl<number | null>;
  recalled: FormControl<number | null>;
}

export interface TradeDocumentExpenseLineForm {
  id: FormControl<string | null>;
  product: FormControl<Product | null>;
  quantity: FormControl<number | null>;
  vatType: FormControl<Vat | null>;
  vatPrice: FormControl<number | null>;
  price: FormControl<number | null>;
  reserved: FormControl<number | null>;
  discountPricePercent: FormControl<number | null>;
  demanded: FormControl<number | null>;
  recalled: FormControl<number | null>;
}
